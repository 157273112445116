
.hide {
  display: none;
}
    
.image:hover + .hide {
  display: block;
}

.hide:hover {
  display: block;
}